import React, { Fragment } from 'react';
import { StrapiImage } from '../../types';
import { Avatar, Text, AvatarProps } from '@chakra-ui/react';
import { getStrapiMedia } from '../../lib/media';

interface AuthorProps {
  name: string;
  avatar: { data: StrapiImage };
}

function Author({ name, avatar, ...avatarProps }: AuthorProps & AvatarProps) {
  return getStrapiMedia(avatar.data).map(url =>
    <span className="flex items-center">
      <Avatar
        {...avatarProps}
        className="mr-1"
        src={url}
      />
      <Text className="italic">{name}</Text>
    </span>
  ).unwrapOr(<Fragment />);
}

export { Author };
