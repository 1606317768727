import { Badge } from '@chakra-ui/react';
import type { GetServerSidePropsResult, NextPage } from 'next';
import { Seo } from '../components/Seo/Seo';
import { Articles } from '../container/Articles';
import { fetchAPI } from '../lib/api';
import { Article, LayoutConfig, StrapiHomepage } from '../types';

interface HomeProps {
  articles: Article[];
  homepage: StrapiHomepage;
}

const Home: NextPage<HomeProps> = ({ articles, homepage }) => {
  return (
    <>
      <Seo seo={homepage.attributes.defaultSeo} />
      <section className="container min-w-full">
        <Articles
          asList={false}
          articles={articles}
          generateBadge={(article, i) =>
            i === 0 ? <Badge colorScheme="purple">neu</Badge> : undefined
          }
        />
      </section>
    </>
  );
};

type StaticHomeProps = GetServerSidePropsResult<HomeProps & LayoutConfig>;

export async function getServerSideProps(): Promise<StaticHomeProps> {
  const [articlesRes, homepageRes] = await Promise.all([
    fetchAPI<Article[]>('/articles', {
      sort: 'rank:asc',
      populate: ['cover', 'category', 'blocks.0', 'author', 'author.avatar'],
    }),
    fetchAPI<StrapiHomepage>('/global', {
      populate: { defaultSeo: { populate: '*' } },
    }),
  ]);

  return {
    props: {
      articles: articlesRes.data,
      homepage: homepageRes.data,
      layout: {
        containerWidth: 'full',
        showEvents: true
      }
    },
  };
}

export default Home;
