import React, { PropsWithChildren } from 'react';
import Link from 'next/link';
import { Card } from '../components/Card/Card';
import { Article } from '../types';
import {
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Badge,
} from '@chakra-ui/react';
import { format, parse } from 'date-fns';
import { de } from 'date-fns/locale';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import ReactMarkdown from 'react-markdown';
import { Author } from '../components/Author';
import { formatDate } from '../utils/dateUtils';
import clsx from 'clsx';
import { BsMouse, BsMouseFill } from 'react-icons/bs';
import { Some } from '@sniptt/monads';

interface ArticlesProps {
  articles: Article[];
  generateBadge?: (article: Article, i: number) => JSX.Element | undefined;
  asList: boolean
}

type ArticleLink = PropsWithChildren<{
  href: string;
  className?: string;
}>;

function ArticleLink({ href, children, className }: ArticleLink) {
  return (
    <Link href={href} className={clsx(className, 'focus:outline-none focus:ring focus:ring-violet-300')}>
      {children}
    </Link>
  );
}

function Articles({
  articles,
  generateBadge = () => undefined,
  asList
}: ArticlesProps) {

  const firstElementAvatarSize = useBreakpointValue({
    base: 'md',
    md: 'md',
    xs: 'xs',
  });

  return (
    <div className="articles my-0 w-full px-0">
      {articles.length === 0 && (
        <Text className="italic">
          hmm... Hier scheint es noch nichts zu geben.
        </Text>
      )}
      <Grid
        templateColumns="repeat(12, minmax(0, 1fr))"
        gap={6}
        className="min-w-full grid"
      >
        {articles.map((article, i) => (
          <GridItem
            key={article.id}
            w="100%"
            className={clsx({
              "h-fit": i === 0,
              "col-span-full md:col-span-8": !asList && i === 0,
              "col-span-full md:col-span-4": !asList && i === 1,
              "col-span-full md:col-span-6": !asList && i > 1,
              "col-span-12": asList,
              "min-h-20": true
            })}
          >
            <Card
              key={article.id}
              title={article.attributes.title}
              header={
                <>
                  <p className="sr-only">Veröffentlichungsdatum: </p>
                  <Text fontSize="xs" className="italic">
                    {formatDate(new Date(article.attributes.publishedAt))}
                  </Text>
                </>
              }
              tags={[
                article.attributes.category?.data?.attributes?.name || '',
              ].filter(Boolean)}
              badge={generateBadge(article, i)}
              image={!asList ? article.attributes.cover.data : undefined}
              as={<ArticleLink href={`/article/${article.attributes.slug}`} />}
              footer={
                Some(article.attributes.author).map(author => <Author
                  size={i === 0 ? firstElementAvatarSize : 'xs'}
                  name={author.data.attributes.name}
                  avatar={author.data.attributes.avatar}
                />).unwrapOr(<></>)
              }
              {...(i === 0 ? { size: 'max-h-80' } : {})}
            >
              {i === 0 && (
                <ReactMarkdown components={ChakraUIRenderer()}>
                  {article.attributes.teaser}
                </ReactMarkdown>
              )}
            </Card>
          </GridItem>
        ))}
      </Grid>
    </div>
  );
}

export { Articles };
