import React from 'react';
import Head from 'next/head';
import { useContext } from 'react';
import { getStrapiMedia } from '../../lib/media';
import { GlobalContext } from '../../pages/_app';
import { StrapiSeo } from '../../types';
import { Some } from '@sniptt/monads';

function Seo({ seo }: { seo: StrapiSeo }) {
  const { defaultSeo, siteName } = useContext(GlobalContext);

  const seoWitDefaults = {
    ...defaultSeo,
    ...seo,
  };

  const fullSeo = {
    ...seoWitDefaults,
    metaTitle: Some(seoWitDefaults.metaTitle).map(t => `${seoWitDefaults.metaTitle} | ${siteName}`).unwrapOr(siteName),
    shareImage: Some(seoWitDefaults).andThen(seo => getStrapiMedia(seo.shareImage?.data)).match({ some: (v) => v, none: null})
  };

  return (
    <div aria-hidden>
      <Head>
        {fullSeo.metaTitle && (
          <>
            <title>{fullSeo.metaTitle}</title>
            <meta property="og:title" content={fullSeo.metaTitle} />
            <meta property="twitter:title" content={fullSeo.metaTitle} />
          </>
        )}
        {fullSeo.metaDescription && (
          <>
            <meta name="description" content={fullSeo.metaDescription} />
            <meta property="og:description" content={fullSeo.metaDescription} />
            <meta name="twitter:description" content={fullSeo.metaDescription} />
          </>
        )}
        {fullSeo.shareImage && (
          <>
            <meta property="og:image" content={fullSeo.shareImage} />
            <meta name="twitter:image" content={fullSeo.shareImage} />
            <meta name="image" content={fullSeo.shareImage} />
          </>
        )}
        {fullSeo.article && <meta property="og:type" content="article" />}
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
    </div>
  );
}

export { Seo };
