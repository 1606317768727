import React from 'react';
import { StrapiImage } from '../../types';
import { DynamicImage } from '../Image/Image';
import { Badge, Heading, Text } from '@chakra-ui/react';
import clsx from 'clsx';

interface CardProps {
  as?: React.ReactElement<any>;
  image?: StrapiImage;
  title: string;
  tags?: string[];
  footer?: React.ReactElement<any>;
  header?: React.ReactElement<any>;
  badge?: React.ReactElement<any> | React.ReactElement<any>[];
  size?: string
}

const cardcss = clsx('card grid bg-white dark:bg-default-500 h-full w-full grid-rows-10 ');

const defaultCard = <article></article>;

function Card({
  as = defaultCard,
  image,
  badge,
  title,
  header,
  tags,
  footer,
  children,
  size = 'max-h-60'
}: React.PropsWithChildren<CardProps>) {
  const renderImage = image && (
    <DynamicImage
      alt={image.attributes.alternativeText}
      key="card-image"
      image={image}
      className={clsx("card-media object-cover row-span-4", size)}
    />
  );

  const badges: React.ReactElement<any>[] = badge
    ? Array.isArray(badge)
      ? badge
      : [badge]
    : [];

  const content = React.cloneElement(
    as,
    {
      className: `${as.props.className || ''} ${cardcss}`.trim(),
    },
    [
      badges && (
        <span
          key="card-badges"
          className="card-badge left-3 top-3 z-30 flex justify-end absolute right-0"
        >
          {badges.map((element, i) =>
            React.cloneElement(element, {
              key: i,
              className: 'mr-1',
            })
          )}
        </span>
      ),
      renderImage,
      <div
        key="card-heading"
        className="card-heading row-span-1 w-full pl-1 pb-2 pt-2"
      >
        {tags && (
          <div key="card-tags" className="card-tags row-span-1 ml-1 mb-0">
            {tags.map((tag) => (
              <Text key={tag} fontSize="xs" className="mr-2 text-blue-600 dark:text-gray-200">
                {tag}
              </Text>
            ))}
          </div>
        )}
        <Heading
          className="ml-1 overflow-hidden text-ellipsis"
          key="card-title"
          as="h3"
          size="lg"
        >
          {title}
        </Heading>
        <div className="ml-1">{header}</div>
      </div>,
      <div key="card-body" className="card-body row-span-4 pl-2">
        {React.Children.toArray(children)}
      </div>,
      footer && (
        <div
          key="card-footer"
          className="card-footer row-span-1 flex pl-3 pb-2"
        >
          {footer}
        </div>
      ),
    ].filter(Boolean)
  );

  return (
    <div className="card-container relative h-full w-full overflow-hidden rounded-default shadow-default shadow-slate-300/60">
      {content}
    </div>
  );
}

export { Card };
